'use strict';

import { on, off, throttle, docDisableScroll, docEnableScroll, insertAfter, isInViewport } from './modules/_fn';

/* ---------------------------------------- [START] IE Global Setting */
// 舊IE提醒
const userAgent = window.navigator.userAgent;
if (
	userAgent.indexOf('MSIE 7.0') > 0 ||
	userAgent.indexOf('MSIE 8.0') > 0 ||
	userAgent.indexOf('MSIE 9.0') > 0 ||
	userAgent.indexOf('MSIE 10.0') > 0 ||
	!!userAgent.match(/Trident.*rv\:11\./) || // IE11
	navigator.userAgent.indexOf('Firefox') >= 0 
) {
	location.href = '/browser.html';
}
/* ---------------------------------------- [END] IE Global Setting */

/* ---------------------------------------- [START] Document Ready */
(function (window, document) {
	/* ---------------------------------------- [START] Windows Setting */
	const html = document.documentElement;
	const body = document.body || document.querySelector('body');
	let ww = window.innerWidth;
	let wh = window.innerHeight;
	let ws = 0;
	function getScrollTop(target = window) {
		return (target.pageYOffset || html.scrollTop) - (html.clientTop || 0);
	}
	function getWinSet() {
		ww = window.innerWidth;
		wh = window.innerHeight;
		ws = getScrollTop();
	}
	on(window, 'load', getWinSet);
	on(window, 'resize', throttle(getWinSet, 50, 100));
	/* ---------------------------------------- [END] Windows Setting */

	/* ---------------------------------------- [START] 取得裝置判斷 */
	// 取得裝置判斷
	let isMobile = false;
	let isTablet = false;
	let isPhone = false;

	const deviceDetect = function () {
		// IsPhone
		isPhone = ww <= 640;

		// IsMobile
		// 防止測試時一直用開發者工具Resize出現Bug
		isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		if(isMobile) html.classList.add('is-mobile');
		else html.classList.remove('is-mobile');

		// IsTablet
		if (navigator.userAgent.match(/Android/i)) {
			if (!navigator.userAgent.match(/Mobile/i)) {
				isTablet = true;
			}
		} else if (navigator.userAgent.match(/BlackBerry|iPad|Opera Mini|IEMobile/i)) {
			isTablet = true;
		}
	};

	deviceDetect();
	on(window, 'resize', throttle(deviceDetect, 50, 100));
	/* ---------------------------------------- [END] 取得裝置判斷 */

	/* ---------------------------------------- [START] 判斷browser */
	var ua = navigator.userAgent;
	var browser = {
		isChrome: /chrome/i.test(ua),
		isFirefox: /firefox/i.test(ua),
		isSafari: /safari/i.test(ua),
		isIE: /msie/i.test(ua) || /trident/i.test(ua),
		isEdge: /edge/i.test(ua),
	};

	// 修正數值browser
	if (browser.isChrome) browser.isSafari = false;
	if (browser.isEdge) {
		browser.isChrome = false;
		browser.isSafari = false;
	}

	var browserIs;
	for (var key in browser) {
		if (browser[key]) {
			browserIs = key.split('is')[1];
			// 增加Class
			document.documentElement.classList.add(browserIs.toLowerCase());
			document.documentElement.browser = browserIs;
			break;
		}
	}
	browser.is = browserIs;

	// ----------------------------
	// 判斷 裝置
	// iOS
	var isiOS = ua.toLowerCase().match(/(iphone|ipod|ipad)/);
	isiOS && html.classList.add('ios');
	/* ---------------------------------------- [END] 判斷browser */

	/* ----------------------------------- [START] Loader 移除 */
	var loaderRemove = function () {
		var loader = document.querySelector('#loader');
		window.loader = loader; // 加到 window 上

		pageLoaded();
	};
	on(window, 'load', loaderRemove);

	/* 頁面可呼叫的 function -------- */
	/* 開啟 Loading */
	window.pageLoading = function() {
		loader.classList.remove('loaded');
		document.body.appendChild(loader);
	}

	/* 關閉 Loading */
	window.pageLoaded = function() {
		loader.classList.add('loaded');
		setTimeout(function () {
			loader.remove();
		}, 2500);
	}
	/* ----------------------------------- [END] Loader 移除 */

	/* ----------------------------------- [START] Gotop */
    function clickScrollTop($target) {
		$(document).on('click', $target, function () {
			$('html, body').animate({
				scrollTop: 0
			}, 550);
		});
	}
  
	clickScrollTop('.gotop'); // scroll top
	/* ----------------------------------- [END] Gotop */

	/* ---------------------------------------- [START] Lazyload */
	/*
		* 使用：https://github.com/tuupola/lazyload
		* Angular需要另外尋找套件來建立
		* 尋找頁面上的 .lazyload 為執行 lazy load 物件
		*/
		function buildLazyLoad() {
			lazyload();
		}
		window.addEventListener('load', buildLazyLoad);
	/* ---------------------------------------- [END] Lazyload */

	/* ---------------------------------------- [START] Fetch Svg Inline */
	function buildSvgFetchInline() {
		if (fetchSvgInline === undefined) {
			return false
		}

		const svgImgs = document.querySelectorAll('img.svg');
		[].forEach.call(svgImgs, (svg) => {
			fetchSvgInline(svg)
		});
	}
	on(window, 'load', buildSvgFetchInline);
	/* ---------------------------------------- [END] Fetch Svg Inline */

	/* ----------------------------------- [START] Foundation & FacebookMessenger */
	on(window, 'load', function () {
		// Start
		$(document).foundation();
		
		// //- <!-- Your SDK code -->
		var chatbox = document.getElementById('fb-customer-chat');
		chatbox.setAttribute('page_id','102750445912531');
		chatbox.setAttribute('attribution','biz_inbox');

		
		window.fbAsyncInit = function() {
			FB.init({
				xfbml            : true,
				version          : 'v15.0'
			});
		};

		(function(d, s, id) {
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) return;
			js = d.createElement(s); js.id = id;
			js.src = 'https://connect.facebook.net/zh_TW/sdk/xfbml.customerchat.js';
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));
	});
	/* ----------------------------------- [END] Foundation */

	/* ----------------------------------- [START] Header&Scroll */
	on(window, 'load', function () {
		$("html").easeScroll({
			frameRate: 20,
			animationTime: 2000,
			stepSize: 35,
			pulseAlgorithm: !0,
			pulseScale: 8,
			pulseNormalize: 1,
			accelerationDelta: 20,
			accelerationMax: 1,
			keyboardSupport: !0,
			arrowScroll: 0,
			// disabledClass: 'modal-open',

			/* Browser Setting Control */
			browser: {
				Chrome: true,
				FireFox: false,
				Safari: false,
				IE: true,
				Edge: true,
			},
		});

		$('.hd-menu_bgr').on('click' , function(){
			$('.hd-menu').toggleClass('open');
			$('.hd-logo').toggleClass('open');
		});
	});
	on(window, 'scroll', function () {
		
		var win = $(window),
		ww = win.innerWidth(),
		wh = win.innerHeight(),
		window_width = win.width(),
		ws = win.scrollTop();
		var resizeWindow = function(){
			ww = win.innerWidth();
			wh = win.innerHeight();
		}
		var window_top_position = win.scrollTop();
		var hd_height = $('header').height();

		if(window_top_position >= hd_height){
			$('.hd').addClass('scrolling');
			$('.gotop').addClass('scrolling');
		}else{
			$('.hd').removeClass('scrolling');
			$('.gotop').removeClass('scrolling');
		}

	});
	/* ----------------------------------- [END] Header&Scroll */

	/* ---------------------------------------- [START] ScrollTriggerAni */
	// 有滑動到該區增加動態者增加class "js-ani" ， 滑動到該區增加class "is-animated"
	// let aniSec = document.querySelectorAll('.js-ani');
	// const scrollTriggerAniThrottle = throttle(scrollTriggerAni, 200, 500); // 節流作用

	// function scrollTriggerAni() {
	// 	for (var i = 0; i < aniSec.length; i++) {
	// 		if (isInViewport(aniSec[i])) {
	// 			aniSec[i].classList.add('is-animated');
	// 		}
	// 	}

	// 	cleanTrigger();

	// 	/* If load all of the item, stop Listener */
	// 	if (aniSec.length === 0) {
	// 		window.addEventListener('scroll', scrollTriggerAniThrottle);
	// 	}
	// }

	// function cleanTrigger() {
	// 	aniSec = Array.prototype.filter.call(aniSec, function (ani) {
	// 		return !ani.classList.contains('is-animated');
	// 	});
	// }

	// function isInViewport(el) {
	// 	const rect = el.getBoundingClientRect();
	// 	const isVisible = el.offsetHeight !== 0;

	// 	return (
	// 		isVisible &&
	// 		rect.bottom >= 0 &&
	// 		rect.right >= 0 &&
	// 		rect.top + 100 <= (window.innerHeight || document.documentElement.clientHeight) &&
	// 		rect.left <= (window.innerWidth || document.documentElement.clientWidth)
	// 	);
	// }

	// on(window, 'load', scrollTriggerAni);
	// on(window, 'scroll', scrollTriggerAniThrottle);
	/* ---------------------------------------- [END] ScrollTriggerAni */

	/* ---------------------------------------- [START] 選單下滑更改樣式 */
	// const doc = document.documentElement;
	// const header = document.querySelector('#hd');
	// const footer = document.querySelector('#ft');
	// const headerClassScroll = 'is-collapse';
	// function headerScrollHandler() {
	// 	const windowScrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
	// 	if (windowScrollTop > 0) {
	// 		header.classList.add(headerClassScroll);
	// 	} else {
	// 		header.classList.remove(headerClassScroll);
	// 	}

	// 	if (windowScrollTop >= 50 && windowScrollTop + wh <= footer.offsetTop) {
	// 		$('.js-fixd-service').addClass('is-show');
	// 	} else {
	// 		$('.js-fixd-service').removeClass('is-show');
	// 	}
	// }
	// on(window, 'scroll', headerScrollHandler);
	// on(window, 'load', headerScrollHandler);
	/* ---------------------------------------- [END] 選單下滑更改樣式 */
	

})(window, document);
/* ---------------------------------------- [END] Document Ready */
